<div class="page main-signin-wrapper">

    <!-- Row -->
    <div class="row signpages overflow-hidden">
            <div class="col-sm-12 col-lg-5 d-none d-lg-flex details px-4">
              <div class="position-relative m-auto">
                <img class="" src="../../assets/img/brand/logoFeedbakWhite.svg" alt="">
                <span class="d-block text-white mt-3 text-center op-8">¡Bienvenido!</span>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-lg-7 login_form ">
                  <div class="card-body mt-2 mb-2">
                    <div class="row">
                      <div class="col-sm-12">
                        <img src="../../assets/img/brand/logoFeedbak.svg" class="d-lg-none header-brand-img float-left mb-4" alt="logo">
                        <div class="clearfix"></div>
                      </div>
                    </div>
                    <form [formGroup]="loginForm" (ngSubmit)="Submit()" novalidate>
                      <div class="row">
                        <div class="col-12">
                          <h4 class="text-left mb-1">Iniciar sesión</h4>
                          <p class="mb-4 text-muted tx-13 ml-0 text-left">Ingresa al administrador de <b class="text-primary">LMS</b>.</p>
                          <ngb-alert type="primary" [dismissible]="true" *ngIf="expiredSesionAlert">
                            <span class="alert-inner--text">Sesión expirada</span>
                          </ngb-alert>
                          <ngb-alert type="danger" [dismissible]="true" *ngIf="invalidCredentials">
                            <span class="alert-inner--text">Credenciales Invalidas</span>
                          </ngb-alert>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <h6>Usuario</h6>
                          <input class="form-control mb-3" formControlName="username" type="text">
                          <div
                            *ngIf="(loginFormControl.username.touched || submitted) && (loginFormControl.username.errors?.required)"
                            class="tx-danger">
                            <small>Por favor, ingresa tu usuario</small>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-8">
                          <h6>Contraseña</h6>
                          <input class="form-control mb-3 mb-lg-4" formControlName="password" type="password">
                          <div *ngIf="(loginFormControl.password.touched || submitted) && (loginFormControl.password.errors?.required)"
                            class="tx-danger">
                            <small>Por favor, ingresa tu contraseña</small>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4">
                          <h6>NIP</h6>
                          <input class="form-control mb-4 mb-lg-3 mb-lg-4" formControlName="nip" type="password">
                          <div
                            *ngIf="(loginFormControl.nip.touched || submitted) && (loginFormControl.nip.errors?.required)"
                            class="tx-danger">
                            <small>Por favor, ingresa tu NIP</small>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 pr-5 d-none d-lg-flex">
                          <img src="../../assets/img/brand/logoFeedbak.svg" class="img-responsive my-auto">
                        </div>
   
                        <div class="col-sm-12 col-lg-6 pl-lg-0">
   
                        <button class="btn ripple btn-primary btn-block" type="submit" [disabled]="!loginForm.valid">Ingresar</button>
                        </div>
                        <div class="col-sm-12 d-flex d-lg-none pt-4">
                          <img src="../../assets/img/brand/logoFeedbak.svg" style="width: 120px;" class="img-responsive mx-auto">
                        </div>
                      </div>
                    </form>
                  </div>
                
            </div>
  
    </div>
    <!-- End Row -->
  
  </div>


  <ng-template #modalCredentials let-modal>
    <credentials-modal (onClose)="modal.close();" (onConfirm)="updateCredentials();"></credentials-modal>
  </ng-template>
  