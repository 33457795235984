import { Component, OnInit } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { GlobalAlertService } from '../../services/global-alert.service';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss']
})
export class FullLayoutComponent implements OnInit {

  constructor(
    private router: Router,
    public _MatPaginatorIntl: MatPaginatorIntl,
    private globalAlert: GlobalAlertService
  ) { }

  ngOnInit(): void {
    this._MatPaginatorIntl.itemsPerPageLabel = "Registros por pagina :";
    this.globalAlert.openAlert({title: '¡Bienvenido a LMS!', subtitle: "Disfruta de nuestras herramientas y haz de tus capacitaciones toda una experiencia.", icon: 'celebrating', layer: 'confetti', background: 'success'});
  }

  mainSidebarOpen: any;
  hoverEffect($event) {
    this.mainSidebarOpen = $event.type == 'mouseover' ? 'main-sidebar-open' : '';
  }
  
  clickonBody(){
    document.querySelector('.main-body').classList.remove('main-sidebar-show')    
  }


}
